import dayjs from 'dayjs';
import qs from 'qs';

import { ENV } from '@/config/env';
import { getStorage } from '@/utils';

import { getGuestToken } from '../pwa/guest-token/getGuestToken';

export type TSendEventParams = {
  brand: string;
  entryBrand: string;
};

export type TSendEventData = {
  brand: string;
  eventType: string;
  eventSubType: string;
  deviceTimeCreated: string;
  page: {
    url: string;
    height: number;
    width: number;
    type: string;
  };
  device: {
    appName: string;
    userAgent: string;
    height: number;
    width: number;
  };
  user: {
    customerType: string;
    emailId?: string;
    gaClientId?: string;
    gaSessionId?: string;
  };
  browserUUID: string;
};

type TSendEventArgs = { params: TSendEventParams; data: TSendEventData };

export const sendEvent = async ({ params, data }: TSendEventArgs) => {
  const baseUrl = ENV.NEXT_PUBLIC_CLICKSTREAM_API_BASE_URL;

  let clickStreamGuestToken = getStorage('local').getItem('click-stream-guest-token');

  if (!clickStreamGuestToken) {
    const guestTokenResponse = await getGuestToken();

    if (!guestTokenResponse) {
      console.error('Error getting click-stream guess token:');
      return;
    }

    const TOKEN_EXPIRE_BUFFER = 60 * 60; // 60 seconds * 60 minutes = 3600 seconds = 1 hour
    clickStreamGuestToken = guestTokenResponse.access_token;
    const expirationDate = dayjs()
      .add(guestTokenResponse.expires_in - TOKEN_EXPIRE_BUFFER, 'second')
      .toDate();

    getStorage('local').setItem('click-stream-guest-token', clickStreamGuestToken, {
      expires: expirationDate,
    });
  }

  try {
    // Using qs to stringify query parameters
    const queryString = qs.stringify(params);

    const response = await fetch(`${baseUrl}?${queryString}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        anonymousid: data.browserUUID,
        'app-version': ENV.BUILD_VERSION as string,
        authorization: `Bearer ${clickStreamGuestToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.info('Event successfully sent:', data);
  } catch (error) {
    console.error('Error sending event:', error);
  }
};
